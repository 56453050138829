import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { updateBookings, updateCars, updateGaps } from "./States/Actions/Bookings";
import { updateRates } from "./States/Actions/Rates";
import { updateSeasons } from "./States/Actions/Seasons";
import { updateBookingRequests } from "./States/Actions/BookingRequests";
import { GlobalStyles } from "./Components/GlobalStyles.style";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Bookings from "./Pages/Bookings/Bookings";
import Board from "./Pages/Board/Board";
import { useSnackbar } from "notistack";
import { systembackend_ip } from "./Utils/Network";
import SocketContext from "./Utils/SocketContext";
import { getPassword, isAuthenticated } from "./States/Actions/Auth";
import Simulator from "./Pages/Simulator/Simulator";
import Rates from "./Pages/Rates/Rates";
import Commissions from "./Pages/Commissions/Commissions";
import Requests from "./Pages/BookingRequests/BookingRequests";

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // Push notifications
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      console.log('Push notifications are supported.');
    } else {
      console.error('Push notifications are not supported in this browser.');
    }

    // Socket
    const newSocket = io(systembackend_ip, { secure: true, rejectUnauthorized: false });

    newSocket.on("connected", () => {
      console.log("connected to socket");
      if (isAuthenticated()) {
        newSocket.emit("request_cars", getPassword());
        newSocket.emit("request_bookings", getPassword());
        newSocket.emit("request_rates", getPassword());
        newSocket.emit("request_seasons", getPassword());
        newSocket.emit("request_booking_requests", getPassword());
      }
    });

    newSocket.on("update_bookings", (bookings) => {
      updateBookings(bookings);
      updateGaps()
    });
    newSocket.on("update_cars", (cars) => {
      updateCars(cars);
    });
    newSocket.on("update_rates", (rates) => {
      updateRates(rates);
    });
    newSocket.on("update_seasons", (seasons) => {
      updateSeasons(seasons);
    });
    newSocket.on("update_booking_requests", (booking_requests) => {
      updateBookingRequests(booking_requests);
    });

    // Bookings feedback
    newSocket.on("add_booking_success", () =>
      enqueueSnackbar("Reserva adicionada", { variant: "success" })
    );
    newSocket.on("add_booking_failure", () =>
      enqueueSnackbar("Não foi possível adicionar a reserva", {
        variant: "error",
      })
    );
    newSocket.on("remove_booking_success", () =>
      enqueueSnackbar("Reserva apagada", { variant: "success" })
    );
    newSocket.on("remove_booking_failure", () =>
      enqueueSnackbar("Não foi possível apagar a reserva", {
        variant: "error",
      })
    );
    newSocket.on("update_booking_success", () =>
      enqueueSnackbar("Alterações guardadas", { variant: "success" })
    );
    newSocket.on("update_booking_failure", () =>
      enqueueSnackbar("Não foi possível guardar as alterações", {
        variant: "error",
      })
    );
    newSocket.on("reorganize_bookings_success", () =>
      enqueueSnackbar("Reservas reorganizadas", { variant: "success" })
    );
    newSocket.on("reorganize_bookings_failure", () =>
      enqueueSnackbar("Não foi possível reorganizar as reservas", {
        variant: "error",
      })
    );

    // Seasons feedback
    newSocket.on("add_season_success", () =>
      enqueueSnackbar("Época adicionada", { variant: "success" })
    );
    newSocket.on("add_season_failure", () =>
      enqueueSnackbar("Não foi possível adicionar a época", {
        variant: "error",
      })
    );
    newSocket.on("remove_season_success", () =>
      enqueueSnackbar("Época apagada", { variant: "success" })
    );
    newSocket.on("remove_season_failure", () =>
      enqueueSnackbar("Não foi possível apagar a época", {
        variant: "error",
      })
    );

    // Booking Requests feedback
    // newSocket.on("add_booking_request", (booking_requests) => {
    //   updateBookingRequests(booking_requests)
    // }

    // );
    newSocket.on("update_booking_request_success", () => { }//enqueueSnackbar("Pedido atualizado", { variant: "success" })
    );
    newSocket.on("update_booking_request_failure", () =>
      enqueueSnackbar("Não foi possível atualizar o pedido", {
        variant: "error",
      })
    );

    // Rates feedback
    newSocket.on("update_rates_success", () =>
      enqueueSnackbar("Tarifas atualizadas", { variant: "success" })
    );
    newSocket.on("update_rates_failure", () =>
      enqueueSnackbar("Não foi possível atualizar as tarifas", {
        variant: "error",
      })
    );

    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket]);

  return (
    <SocketContext.Provider value={socket}>
      <Router>
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<Login snackbar={enqueueSnackbar} />} />
          <Route path="/inicio" element={<Dashboard />} />
          <Route
            path="/reservas"
            element={<Bookings snackbar={enqueueSnackbar} />}
          />
          <Route path="/quadro" element={<Board snackbar={enqueueSnackbar} />} />
          <Route path="/pedidos" element={<Requests snackbar={enqueueSnackbar} />} />
          <Route path="/simulador" element={<Simulator />} />
          <Route path="/comissoes" element={<Commissions />} />
          <Route path="/tarifas" element={<Rates />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </SocketContext.Provider>
  );
}

export default App;
