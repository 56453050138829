import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "../../Components/Navbar";
import style from "./Board.module.css";
import { isAuthenticated } from "../../States/Actions/Auth";
import { Navigate } from "react-router-dom";
import GapsBoard from "./GapsBoard.js";
import moment from "moment";
import { Grid, MenuItem, Select, Typography } from "@mui/material";
import { changeCalendarMode } from "../../States/Actions/Board";
import EventPopup from "./EventPopup";
import InOutBoard from "./InOutBoard";
import cloneDeep from "lodash.clonedeep";
import { updateBookings } from "../../States/Actions/Bookings";


function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Calendars = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    box_height: window.innerHeight - 80 - 150,
  });
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        box_height: window.innerHeight - 80 - 150,
      });
    }, 1);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  if (!isAuthenticated()) return <Navigate to="/" />;

  let calendar_height = window.innerHeight - 80 - 120;

  return (
    <div>
      <Navbar />
      <div className={style.box}>
        <div>
          <Grid container direction="row" columnGap={10}>
            <Grid item xs={0.1}>
              <Typography
                variant={"title"}
                className={style.title}
                style={{
                  paddingLeft: "10px",
                  transform: "translate(-10px, -5px)",
                  fontFamily: "sf pro text regular",
                }}
              >
                Quadro
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Select
                size="small"
                value={props.calendar_mode}
                onChange={(event, data) => {
                  if (event) changeCalendarMode(data.props.value);
                }}
                style={{
                  width: "167px",
                  height: "22px",
                  fontFamily: "sf pro text regular",
                  fontSize: "14px",
                  color: "#2e3192",
                  transform: "translate(-10px, -5px)",
                }}
              >
                <MenuItem
                  value={"gaps"}
                  style={{ fontFamily: "sf pro text regular" }}
                >
                  Buracos
                </MenuItem>
                <MenuItem
                  value={"in_out"}
                  style={{ fontFamily: "sf pro text regular" }}
                >
                  Entradas e Saidas
                </MenuItem>
              </Select>
            </Grid>
            {/* <Grid item>
              <button onClick={() => sortGaps(props)}>Organizar</button>
            </Grid> */}
            <Grid item>
              <Typography
                variant={"title"}
                className={style.subtitle}
                style={{
                  padding: "4px",
                  border: "1px solid #5053b4",
                  borderRadius: "5px",
                  transform: "translate(0px, -7px)",
                  fontFamily: "arial",
                }}
              >
                {props.interval}
              </Typography>
            </Grid>
          </Grid>

          {props.calendar_mode === "gaps" ? (
            <GapsBoard
              h={window.innerHeight - 80 - 130}
              snackbar={props.snackbar} />
          ) : props.calendar_mode === "in_out" ? (
            <InOutBoard calendar_height={calendar_height} />
          ) : (
            ""
          )}
        </div>
      </div>
      <EventPopup></EventPopup>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    all_bookings: state.bookings.all_bookings,
    cars: state.bookings.cars,
    calendar_mode: state.board.calendar_mode,
    interval: state.board.interval,
  };
}

export default connect(mapStateToProps)(Calendars);
