import moment from "moment";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { connect } from "react-redux";
import Timeline, { CustomMarker, DateHeader, SidebarHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { changeInterval, openEventPopup } from "../../States/Actions/Board";
import "./GapsBoard.css";
import { ReactComponent as RenaultLogo } from "../../Resources/Images/Cars/renault.svg";
import { ReactComponent as SeatLogo } from "../../Resources/Images/Cars/seat.svg";
import { ReactComponent as SmartLogo } from "../../Resources/Images/Cars/smart.svg";
import { ReactComponent as NissanLogo } from "../../Resources/Images/Cars/nissan.svg";
import { ReactComponent as SkodaLogo } from "../../Resources/Images/Cars/skoda.svg";
import { Grid, Typography } from "@mui/material";
import { Box, height, maxHeight } from "@mui/system";
import { styled } from "@mui/material/styles";
import { TbManualGearbox } from "react-icons/tb";
import { GrManual } from "react-icons/gr";
import SocketContext from "../../Utils/SocketContext";
import { getPassword } from "../../States/Actions/Auth";
import { FaLock, FaLockOpen, FaUnlock } from "react-icons/fa";

const itemRenderer = ({
  item,
  timelineContext,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  return (
    <div
      {...getItemProps({
        style: {
          background: item.gap ? "#88888800" : item.bgColor,
          color: item.gap ? "#ffffff00" : "#000000ff",
          border:
            item.gap && itemContext.selected
              ? "1px dotted #000000ff"
              : item.gap
                ? "00000000"
                : itemContext.selected
                  ? "1px solid #000000ff"
                  : "1px solid #bbbbbb66",
          // borderStyle: "solid",
          borderWidth: itemContext.selected ? 2 : 1,
          borderRadius: 4,
          fontFamily: "sf pro text regular",
        },
      })}
    >
      {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

      <div
        style={{
          height: itemContext.dimensions.height,
          overflow: "hidden",
          paddingLeft: 3,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {itemContext.title}
      </div>

      {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
    </div>
  );
};

const groupRenderer = (group, cars) => {
  const car = cars.find((c) => c.Plate === group.group.id);

  var mw = 100;

  if (window.innerWidth < 500) mw = 80;

  mw = mw + "px";

  return (
    <div className="sidebar-group" style={{ maxWidth: "100px" }}>
      {window.innerWidth < 500 ? (
        <Box
          style={{
            maxHeight: "30px",
            transform: "translate(2px, 6px)",
          }}
          display="flex"
        >
          <div
            style={{
              width: "6px",
              background: "#2c55ce",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "3px",
              border: "1px solid black",
              borderRight: "0px",
              marginLeft: "0 auto",
              padding: "0px",
              float: "left",
            }}
          ></div>
          <div
            style={{
              width: "auto",
              height: "100%",
              border: "1px solid black",
              borderLeft: "0px",
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "3px",
              background: "white",
              padding: "0px 4px 0px 4px",
              float: "left",
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                flexWrap: "wrap",
                wordWrap: "break-word",
                fontWeight: "bold",
                color: "#000000",
                fontFamily: "monospace",
              }}
              sx={{
                fontSize: {
                  lg: 10,
                  md: 10,
                  sm: 10,
                  xs: 8,
                },
              }}
            >
              {group.id}
            </Typography>
          </div>
        </Box>
      ) : (
        <Grid
          container
          direction="row"
          columnGap={1}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          style={{ transform: "translate(-2px, -5px)" }}
        >
          <Grid item xs={3}>
            <Grid container direction="row">
              <Grid item>
                <Grid container direction="column">
                  <Grid item style={{ transform: "translate(-2px, 6px)" }}>
                    <Typography
                      style={{
                        fontFamily: "sf pro text regular",
                        fontSize: "12px",
                        color: "#000000",
                        fontWeight: "bold",
                      }}
                    >
                      {car.Group}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {car.Transmission === "Manual" ? (
                      <TbManualGearbox
                        style={{ transform: "translate(-2px, -4px)" }}
                        size={14}
                      ></TbManualGearbox>
                    ) : (
                      <GrManual
                        style={{ transform: "translate(-2px, -4px)" }}
                        size={12}
                      ></GrManual>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ position: "absolute" }}>
                {car.Brand === "Renault" ? (
                  <RenaultLogo
                    style={{
                      maxWidth: "16px",
                      maxHeight: "16px",
                      textAlign: "center",
                      transform: "translate(16px, 8px)",
                    }}
                  />
                ) : car.Brand === "Seat" ? (
                  <SeatLogo
                    style={{
                      maxWidth: "14px",
                      maxHeight: "14px",
                      textAlign: "center",
                      transform: "translate(18px, 7px)",
                    }}
                  />
                ) : car.Brand === "Smart" ? (
                  <SmartLogo
                    style={{
                      maxWidth: "12px",
                      maxHeight: "12px",
                      textAlign: "center",
                      transform: "translate(16px, 6px)",
                    }}
                  />
                ) : car.Brand === "Nissan" ? (
                  <NissanLogo
                    style={{
                      maxWidth: "16px",
                      maxHeight: "16px",
                      textAlign: "center",
                      transform: "translate(16px, 6px)",
                    }}
                  />
                ) : car.Brand === "Skoda" ? (
                  <SkodaLogo
                    style={{
                      maxWidth: "14px",
                      maxHeight: "14px",
                      textAlign: "center",
                      transform: "translate(18px, 6px)",
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ transform: "translate(6px, -5px)" }}>
            <Box
              style={{
                maxHeight: "30px",
              }}
              display="flex"
            >
              <div
                style={{
                  width: "6px",
                  background: "#2c55ce",
                  borderTopLeftRadius: "3px",
                  borderBottomLeftRadius: "3px",
                  border: "1px solid black",
                  borderRight: "0px",
                  marginLeft: "0 auto",
                  padding: "0px",
                  float: "left",
                }}
              ></div>
              <div
                style={{
                  width: "auto",
                  height: "100%",
                  border: "1px solid black",
                  borderLeft: "0px",
                  borderTopRightRadius: "3px",
                  borderBottomRightRadius: "3px",
                  background: "white",
                  padding: "0px 4px 0px 4px",
                  float: "left",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    flexWrap: "wrap",
                    wordWrap: "break-word",
                    fontWeight: "bold",
                    color: "#000000",
                    fontFamily: "monospace",
                  }}
                  sx={{
                    fontSize: {
                      lg: 10,
                      md: 10,
                      sm: 10,
                      xs: 8,
                    },
                  }}
                >
                  {car.Plate}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const color1 = "#8cebf8"
const color2 = "#a3f8dc"
const color3 = "#a9f58c"
const color4 = "#f2f592"
const color5 = "#f1a9ca"


const car_info = {
  "AO-19-TM": { color: color1, place: 1 },
  "AO-31-TM": { color: color1, place: 2 },
  "AO-34-TM": { color: color1, place: 3 },
  "AO-39-TM": { color: color1, place: 4 },
  "AO-41-TM": { color: color1, place: 5 },
  "AO-48-UG": { color: color1, place: 6 },
  "AO-98-TL": { color: color1, place: 7 },
  "AO-99-TL": { color: color1, place: 8 },
  "AQ-05-LF": { color: color2, place: 9 },
  "AQ-11-LF": { color: color2, place: 10 },
  "AQ-71-LF": { color: color2, place: 11 },
  "AQ-94-JQ": { color: color2, place: 12 },
  "AQ-97-JR": { color: color2, place: 13 },
  "AQ-99-JR": { color: color2, place: 14 },
  "AZ-46-OA": { color: color2, place: 15 },
  "AZ-67-PC": { color: color2, place: 16 },
  "AZ-79-PB": { color: color2, place: 17 },
  "AH-34-BA": { color: color3, place: 18 },
  "AZ-11-ZR": { color: color4, place: 19 },
  "AZ-47-OA": { color: color4, place: 20 },
  "AZ-63-TI": { color: color4, place: 21 },
  "AZ-65-TI": { color: color4, place: 22 },
  "BJ-33-OZ": { color: color4, place: 23 },
  "BJ-49-OF": { color: color4, place: 24 },
  "AR-31-VC": { color: color4, place: 25 },
  "AX-58-FP": { color: color4, place: 26 },
  "AX-96-PM": { color: color4, place: 27 },
  "AH-69-IR": { color: color5, place: 28 },
  "BI-27-OF": { color: color5, place: 29 },
  "BI-31-OF": { color: color5, place: 30 },
  "BI-49-OF": { color: color5, place: 31 },
  "BI-76-OF": { color: color5, place: 32 },
  "BR-86-UP": { color: color5, place: 33 },
  "BR-91-UP": { color: color5, place: 34 },
  "CC-CC-CC": { color: color4, place: 35 },
  "DD-DD-DD": { color: color4, place: 36 },
  "EE-EE-EE": { color: color4, place: 37 },
  "FF-FF-FF": { color: color4, place: 38 },
  "GG-GG-GG": { color: color4, place: 39 },
  "HH-HH-HH": { color: color4, place: 40 },
  "II-II-II": { color: color4, place: 41 },
  "JJ-JJ-JJ": { color: color4, place: 42 },
  "LL-LL-LL": { color: color4, place: 43 },
  "MM-MM-MM": { color: color4, place: 44 },
  "NN-NN-NN": { color: color4, place: 45 },
  "OO-OO-OO": { color: color4, place: 46 },
  "PP-PP-PP": { color: color4, place: 47 },
  "QQ-QQ-QQ": { color: color4, place: 48 }
};

const GapsCalendar = (props) => {
  const socket = useContext(SocketContext);

  const [bookings, setBookings] = useState(props.bookings);
  const [cars] = useState(() =>
    props.cars
      .filter(car => Object.keys(car_info).includes(car.Plate))
      .sort((a, b) => car_info[a.Plate].place - car_info[b.Plate].place)
  )

  const groups = useMemo(() =>
    cars.map(car => ({
      id: car.Plate,
      title: car.Plate,
      height: 30//props.h / cars.length,
    })),
    [cars, props.h]
  )

  const [reorganizeLocked, setReorganizeLocked] = useState(true)

  // Enhanced past booking detection
  const isPastBooking = useCallback((booking) => {
    const now = moment();
    const start = moment(
      `${booking.booking_info.Start_Date}T${booking.booking_info.Start_Time}`,
      "DD-MM-YYYYTHH:mm"
    );
    const end = moment(
      `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
      "DD-MM-YYYYTHH:mm"
    );
    return end.isBefore(now) || start.isBefore(now);
  }, []);

  const getMargin = (prevLocation, nextLocation) => {
    const prev = (prevLocation || '').toLowerCase().replaceAll("ó", "o").replaceAll("-", "/");
    const next = (nextLocation || '').toLowerCase().replaceAll("ó", "o");

    if (prev.endsWith('/aeroporto') && next.startsWith('aeroporto')) return 20
    if (prev.endsWith('aeroporto') && next.startsWith('aeroporto')) return 0
    if (prev.startsWith('escritorio/') && next.startsWith('escritorio')) return 20
    if (prev.endsWith('escritorio') && next.startsWith('escritorio')) return 0
    return 60
  };

  // Get latest non-future booking end per car
  const getCarAvailability = useCallback(() => {
    const carAvailability = new Map();

    props.bookings.forEach(booking => {
      if (!isPastBooking(booking)) return;

      const plate = booking.booking_info.Car_Plate;
      const end = moment(
        `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
        "DD-MM-YYYYTHH:mm"
      );

      const existing = carAvailability.get(plate) || {
        time: moment(0),
        location: ''
      };

      if (end.isAfter(existing.time)) {
        carAvailability.set(plate, {
          time: end,
          location: booking.booking_info.End_Local
        });
      }
    });

    return carAvailability;
  }, [props.bookings, isPastBooking]);

  const items = useMemo(() => {
    const bookingItems = [];
    const gapItems = [];

    // Process bookings
    bookings.forEach(booking => {
      if (!booking.booking_info.Car_Plate) return;
      if (!car_info[booking.booking_info.Car_Plate]) return;

      const start = moment(
        `${booking.booking_info.Start_Date}T${booking.booking_info.Start_Time}`,
        "DD-MM-YYYYTHH:mm"
      )

      const end = moment(
        `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
        "DD-MM-YYYYTHH:mm"
      )

      try {
        bookingItems.push({
          id: booking.booking_info.ID,
          group: booking.booking_info.Car_Plate,
          title: booking.booking_info.Client_Name,
          start_time: start,
          end_time: end,
          bgColor: car_info[booking.booking_info.Car_Plate].color,
          selectedBgColor: car_info[booking.booking_info.Car_Plate].color,
          color: "#bbbbbb",
          gap: false,
          label: `${start.format("DD-MM-YYYY HH:mm")} ➞ ${end.format("DD-MM-YYYY HH:mm")}`
        });
      } catch (e) {
        console.error("Invalid car plate:", booking.booking_info.ID);
      }
    });

    // Process gaps
    cars.forEach(car => {
      const carBookings = bookings
        .filter(b => b.booking_info.Car_Plate === car.Plate)
        .sort((a, b) =>
          moment(
            `${a.booking_info.Start_Date}T${a.booking_info.Start_Time}`,
            "DD-MM-YYYYTHH:mm"
          ) - moment(
            `${b.booking_info.Start_Date}T${b.booking_info.Start_Time}`,
            "DD-MM-YYYYTHH:mm"
          )
        );

      for (let i = 0; i < carBookings.length - 1; i++) {
        const start = moment(
          `${carBookings[i].booking_info.End_Date}T${carBookings[i].booking_info.End_Time}`,
          "DD-MM-YYYYTHH:mm"
        );
        const end = moment(
          `${carBookings[i + 1].booking_info.Start_Date}T${carBookings[i + 1].booking_info.Start_Time}`,
          "DD-MM-YYYYTHH:mm"
        );

        if (end.diff(start, 'minutes') >= 360) {
          gapItems.push({
            id: `gap_${carBookings[i].booking_info.ID}_${carBookings[i + 1].booking_info.ID}`,
            group: car.Plate,
            start_time: start,
            end_time: end,
            gap: true,
            label: `${start.format("DD-MM-YYYY HH:mm")} ➞ ${end.format("DD-MM-YYYY HH:mm")}`
          });
        }
      }
    });

    return [...bookingItems, ...gapItems];
  }, [bookings, cars]);

  // Helper to get booking time as timestamp
  const getBookingTimes = useCallback((booking) => {
    const start = moment(
      `${booking.booking_info.Start_Date}T${booking.booking_info.Start_Time}`,
      "DD-MM-YYYYTHH:mm"
    );
    const end = moment(
      `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
      "DD-MM-YYYYTHH:mm"
    );
    return { start: start.valueOf(), end: end.valueOf() };
  }, []);

  const shouldLock = (booking) => {
    return ["oficina", "autozarco"].includes(booking.booking_info.Client_Name.toLowerCase())
  }

  const getLastLockedTimes = (bookings) => {
    const lastLockedMap = new Map();

    bookings.forEach(booking => {
      if (shouldLock(booking)) {
        const end = moment(
          `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
          "DD-MM-YYYYTHH:mm"
        );
        const plate = booking.booking_info.Car_Plate;

        if (!lastLockedMap.has(plate) || end.isAfter(lastLockedMap.get(plate))) {
          lastLockedMap.set(plate, end);
        }
      }
    });

    return lastLockedMap;
  };

  // Optimized reorganization logic
  const handleReorganize = useCallback((snackbar, socket) => {
    if (props.overlapped_bookings_num > 0) {
      snackbar("Reservas sobrepostas", { variant: "error" });
      return
    }

    const carAvailability = getCarAvailability();
    const now = moment();
    const lastLockedTimes = getLastLockedTimes(props.bookings);

    let _bookings = props.bookings.sort((a, b) =>
      moment(`${a.booking_info.Start_Date}T${a.booking_info.Start_Time}`, "DD-MM-YYYYTHH:mm") -
      moment(`${b.booking_info.Start_Date}T${b.booking_info.Start_Time}`, "DD-MM-YYYYTHH:mm")
    );

    const [futureBookings, pastBookings] = _bookings.reduce(
      (acc, booking) => {
        const times = getBookingTimes(booking);
        acc[times.start >= now ? 0 : 1].push(booking);
        return acc;
      },
      [[], []]
    );

    // Identify locked future bookings
    const [lockedBookings, optimizableBookings] = futureBookings.reduce(
      (acc, booking) => {
        const plate = booking.booking_info.Car_Plate;
        const bookingStart = moment(
          `${booking.booking_info.Start_Date}T${booking.booking_info.Start_Time}`,
          "DD-MM-YYYYTHH:mm"
        );

        // Check if booking is locked
        const isLocked = shouldLock(booking);
        const lastLockEnd = lastLockedTimes.get(plate);
        const isBeforeLastLock = lastLockEnd && bookingStart.isBefore(lastLockEnd);

        acc[isLocked || isBeforeLastLock ? 0 : 1].push(booking);
        return acc;
      },
      [[], []]
    );

    const groupMap = cars.reduce((acc, car) => {
      const group = car.Group;
      acc[group] = acc[group] || [];
      acc[group].push({
        ...car,
        lastUsed: carAvailability.get(car.Plate) || moment(0),
      });
      return acc;
    }, {});

    // Process locked bookings first to update car availability
    lockedBookings.forEach(booking => {
      const plate = booking.booking_info.Car_Plate;
      const group = booking.booking_info.Car_Group;
      const end = moment(
        `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
        "DD-MM-YYYYTHH:mm"
      );

      // Find the car in groupMap and update its availability
      const carGroup = groupMap[group];
      if (!carGroup) return;

      const car = carGroup.find(c => c.Plate === plate);
      if (!car) return;

      // Update groupMap's car availability
      car.lastUsed.time = end;
      car.lastUsed.location = booking.booking_info.End_Local;
    });

    const optimizedBookings = optimizableBookings.map(booking => {
      const group = groupMap[booking.booking_info.Car_Group];
      if (!group) return booking;

      const bookingStart = moment(
        `${booking.booking_info.Start_Date}T${booking.booking_info.Start_Time}`,
        "DD-MM-YYYYTHH:mm"
      );
      const bookingEnd = moment(
        `${booking.booking_info.End_Date}T${booking.booking_info.End_Time}`,
        "DD-MM-YYYYTHH:mm"
      );

      // Find best candidate car in group
      const candidates = group
        .map(car => {
          const lastBooking = carAvailability.get(car.Plate);
          const requiredMargin = getMargin(
            lastBooking?.location,
            booking.booking_info.Start_Local
          );

          const availableFrom = car.lastUsed.time.clone().add(requiredMargin, 'minutes');
          const rawGap = bookingStart.diff(car.lastUsed.time, 'minutes');

          return {
            ...car,
            requiredMargin,
            availableFrom,
            rawGap
          };
        })
        .filter(car => bookingStart.isSameOrAfter(car.availableFrom))  // Ensure margin is respected
        .sort((a, b) => a.rawGap - b.rawGap);  // Sort by smallest actual time between bookings

      if (candidates.length > 0) {
        const bestCar = candidates[0];

        // Update car availability
        bestCar.lastUsed.time = bookingEnd;
        bestCar.lastUsed.location = booking.booking_info.End_Local;
        group.find(c => c.ID === bestCar.ID).lastUsed.time = bookingEnd
        group.find(c => c.ID === bestCar.ID).lastUsed.location = booking.booking_info.End_Local
        return {
          ...booking,
          booking_info: {
            ...booking.booking_info,
            Car_Plate: bestCar.Plate,
            Car_Brand: bestCar.Brand,
            Car_Model: bestCar.Model,
            Car_Group: bestCar.Group
          }
        };
      }

      const originalCar = group.find(c => c.Plate === booking.booking_info.Car_Plate);
      if (originalCar) {
        originalCar.lastUsed.time = bookingEnd;
        originalCar.lastUsed.location = booking.booking_info.End_Local;
      }

      return booking;
    });

    const updatedBookings = [...pastBookings, ...lockedBookings, ...optimizedBookings]

    // Find changed bookings by comparing with original
    const originalBookingsMap = new Map(
      props.bookings.map(b => [b.booking_info.ID, b])
    );

    const changedBookings = updatedBookings.filter(ub => {
      const original = originalBookingsMap.get(ub.booking_info.ID);
      return original &&
        ub.booking_info.Car_Plate !== original.booking_info.Car_Plate;
    });

    // Update local state
    setBookings(updatedBookings);

    // Send only changed bookings to backend
    if (changedBookings.length > 0 && !reorganizeLocked) {
      socket.emit("reorganize_bookings", {
        password: getPassword(),
        data: changedBookings.map(b => ({
          ...b.booking_info,
          // Ensure all required fields are included
          id: b.booking_info.ID,
          insurance: b.booking_info.Insurance === 1
        }))
      });
    }
  }, [cars, props.bookings, reorganizeLocked, getCarAvailability, isPastBooking]);

  const handleReset = useCallback(() => setBookings(props.bookings), [props.bookings]);

  for (var i = cars.length - 1; i >= 0; i--) {
    if (!Object.keys(car_info).includes(cars[i].Plate)) cars.splice(i, 1);
  }

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
        <button
          style={{
            color: "#fff",
            backgroundColor: "#2e3192",
            borderRadius: "4px",
          }}
          onClick={() => handleReorganize(props.snackbar, socket)} >
          Organizar
        </button>
        <button
          style={{
            color: "#2e3192",
            border: "1px solid #2e3192",
            borderRadius: "4px",
          }}
          onClick={handleReset} >
          Reset
        </button>
        {reorganizeLocked ?
          <FaLock
            style={{ color: "#2e3192", marginLeft: "30px" }}
            onClick={() => setReorganizeLocked(!reorganizeLocked)}
          /> :
          <FaLockOpen
            style={{ color: "#2e3192", marginLeft: "30px" }}
            onClick={() => setReorganizeLocked(!reorganizeLocked)}
          />}

      </div>
      <Timeline
        style={{
          color: "#000000",
          backgroundColor: "#ffffff",
          border: "1px",
          borderRadius: "15px",
          boxShadow: "0px 0px 20px 1px #dddddd",
          transform: "translate(0, 6px)",
          maxHeight: window.innerHeight - 170,
          overflow: "scroll"
        }}
        groups={groups}
        items={items}
        canMove={false}
        canResize
        itemHeightRatio={0.92}
        //itemsSorted
        //stackItems
        defaultTimeStart={moment().startOf("day").subtract(1, "day").toDate()}
        defaultTimeEnd={moment().startOf("day").add(30, "day").toDate()}
        itemRenderer={itemRenderer}
        groupRenderer={(group) => groupRenderer(group, props.cars)}
        onItemClick={(id, event) => {
          if (!id.toString().includes("gap")) openEventPopup(id);
        }}
        onItemSelect={(id) => {
          let booking = items.filter((item) => {
            if (item.id === id) return true;
            else return false;
          })[0];

          changeInterval(booking.label);
        }}
      >
        <TimelineHeaders className="sticky">
          <SidebarHeader>
            {({ getRootProps }) => {
              return <div {...getRootProps()}></div>;
            }}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker />
        </TimelineMarkers>
      </Timeline>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    bookings: state.bookings.all_bookings,
    overlapped_bookings_num: state.bookings.overlapped_bookings_num,
    cars: state.bookings.cars,
  };
}

export default connect(mapStateToProps)(GapsCalendar);
